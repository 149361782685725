import React from 'react'
import './ButtonPrimary.scss'

export default function ButtonPrimary({children}) {
    return (
        <button className='button-primary'>
            {children}
        </button>
    )
}
