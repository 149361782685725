import React from 'react'
import { FaCamera } from 'react-icons/fa';
import './scenarij.scss'

export default function Scenarij({ children }) {
    return (
        <div className='scenarij'>
            <FaCamera className='scenarij-icon' />
            <div className="scenarij-children">
                {children}
            </div>
        </div>
    )
}
