import React from 'react'
import './autorskianimiranifilmovi.scss'

export default function AutorskiAnimiraniFilmovi({ children }) {
    return (
        <div className='autorski-animirani-filmovi'>
            <h3 className='autorski-animirani-filmovi-title'>Author's animated films</h3>
            {children}
        </div>
    )
}
