import React from 'react'
import './festivalifilmovi.scss'

export default function FestivaliFilmovi({ children }) {
    return (
        <div className='festivali-filmovi'>
            <h3 className='festivali-filmovi-title'>Festivals at which his author's films were shown</h3>
            {children}
        </div>
    )
}
