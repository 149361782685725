import React from "react"
import './footer.scss'

const currentYear = new Date().getUTCFullYear()

export default function Footer() {
  return (
    <footer className="footer">
      <small>© Kreativna Kuca {currentYear}</small><p className='footer-webmaster'><a className='footer-webmaster-link'  href="mailto:dominikloncar@outlook.com">Web Master: dominikloncar@outlook.com</a></p>
    </footer>
  )
}
