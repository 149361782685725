import React from "react"
import { FaAward } from "react-icons/fa"
import "./awards.scss"

export default function Awards({ children }) {
  return (
    <div className="awards">
      <h3 className="awards-title">
        Awards <FaAward className="awards-icon" />
      </h3>
      {children}
    </div>
  )
}
