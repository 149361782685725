import React from 'react'
import './contacts.scss';

export default function Contacts({ children }) {
    return (
        <div className="contacts">
            {children}
        </div>
    )
}
