import React from "react"
import "./list.scss"
import { Link } from "gatsby"

export default function List({ title, icon, color, link, children }) {
  console.log(link)

  return (
    <div className="list">
      <Link className="list-checkout-all-link" to={link}>
        <h2 className="list-title">{title}</h2>
      </Link>
      <hr className="list-line" />
      <div className="list-children">{children}</div>
      <Link className="list-checkout-all-link" to={link}>
        <p className="list-checkout-all">Show more</p>
      </Link>
    </div>
  )
}
