import React from 'react'
import { FaStarOfLife } from 'react-icons/fa';
import './nomination.scss'

export default function Nomination({ children }) {
    return (
        <div className='nomination'>
            <FaStarOfLife className='nomination-icon' />
            <div className="nomination-children">
                {children}
            </div>
        </div>
    )
}
