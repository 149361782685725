import React, { useState } from "react"
import { Link } from "gatsby"
import { Logo } from "./index"
import "./header.scss"

export default function Header() {
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  function handleNavBtnClick() {
    setIsHamburgerClicked(prevState => {
      return !prevState
    })
  }

  const mobileNavBarStyle = {
    right: `${isHamburgerClicked ? "" : "10000px"}`,
  }

  return (
    <div className="header">
      <Logo />
      <div className="hamburger" onClick={handleNavBtnClick}>
        <div className="hamburger-part"></div>
        <div className="hamburger-part"></div>
        <div className="hamburger-part"></div>
      </div>
      <ul className="header-links" style={mobileNavBarStyle}>
        <li>
          <Link to="/authors">Authors</Link>
        </li>
        <li>
          <Link to="/books">Books</Link>
        </li>
        <li>
          <Link to="/animated-films">Animated Films</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </div>
  )
}
