import React from 'react'
import { FaTrophy } from 'react-icons/fa';
import './award.scss';

export default function Award({ children }) {
    return (
        <div className='award'>
            <FaTrophy className='award-icon' />
            <div className="award-children">{children}
            </div>
        </div>
    )
}
