import React from 'react'
import { FaBookReader } from 'react-icons/fa';
import './knjizevnodjelo.scss'

export default function KnjizevnoDjelo({ children }) {
    return (
        <div className='knjizevno-djelo'>
            <FaBookReader className='knjizevno-djelo-icon' />
            <div className="knjizevno-djelo-children">
                {children}
            </div>
        </div>
    )
}
