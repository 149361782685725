import React from "react"
import "./banner.scss"

export default function Banner({
  children,
  title,
  subtitleTop,
  subtitleBottom,
  price,
}) {
  return (
    <div className="banner">
      <div className="banner-elements">
        <h1 className="banner-title">{title}</h1>
        <p
          className="banner-subtitle"
          style={{ marginBottom: "0px", marginTop: "-23px" }}
        >
          {subtitleTop}
        </p>
        {children}
        <em>
          <p className="banner-subtitle" style={{ paddingBottom: "35px" }}>
            {subtitleBottom}
          </p>
        </em>
      </div>
    </div>
  )
}
