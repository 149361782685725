import React from 'react'
import { FaInfo } from 'react-icons/fa';
import './aboutorg.scss'

export default function AboutOrg({ title, children }) {
    return (
        <div className='about-org'>
            <h1 className='about-org-title'>{title}<FaInfo className='about-org-icon' /> </h1>
            <div className="about-org-children">{children}
            </div>
        </div >
    )
}