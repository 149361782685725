import React from 'react'
import './title.scss'

export default function Title({ title, subtitle, children }) {
    return (
        <div className='title'>
            <h1 className='title-text'>{title}</h1>
            <p className='subtitle-text'><em>{subtitle}</em></p>
            {children}
        </div>
    )
}
