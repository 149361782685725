import React from 'react'
import { FaFilm } from 'react-icons/fa';
import './filmglavnianimator.scss'

export default function FilmGlavniAnimator({ children }) {
    return (
        <div className='film-glavni-animator'>
            <FaFilm className='film-glavni-animator-icon' />
            <div className="film-glavni-animator-children">
                {children}
            </div>
        </div>
    )
}
