import React from "react"
import { FaFilm } from "react-icons/fa"
import "./scenariji.scss"

export default function Scenariji({ children }) {
  return (
    <div className="scenariji">
      <h3 className="scenariji-title">
        Scenarios <FaFilm className="scenariji-icon" />
      </h3>
      {children}
    </div>
  )
}
