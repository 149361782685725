import React from 'react'
import { FaBookOpen } from 'react-icons/fa';
import './medunarodnaizlozba.scss'

export default function MedunarodnaIzlozba({ children }) {
    return (
        <div className='medunarodna-izlozba'>
            <FaBookOpen className='medunarodna-izlozba-icon' />
            <div className="medunarodna-izlozba-children">
                {children}
            </div>
        </div>
    )
}
