import React from 'react'
import { FaFlag } from 'react-icons/fa';
import './festivalifilm.scss'

export default function FestivaliFilm({ children }) {
    return (
        <div className='festivali-film'>
            <FaFlag className='festivali-film-icon' />
            <div className="festivali-film-children">
                {children}
            </div>
        </div>
    )
}
