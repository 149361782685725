import React from 'react'
import { Link } from 'gatsby';

export default function ListItem({children, link}) {
    const El = link ? Link : React.Fragment
    return (
            <El className='list-link-wrapper' to={`/${link}`}>
                <div className='list-item'>
                    <div className='list-item-div'>{children}</div>
                    <hr className='list-line'/>
                </div>
            </El>
    )
}