import React from "react"
import { Header, Footer } from "./index"
import './layout.scss'

export default function Layout(props) {
  return (
    <div className="layout">
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}
