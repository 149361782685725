import React from 'react'
import { FaCameraRetro } from 'react-icons/fa';
import './cartoon.scss'

export default function Cartoon({ children, title }) {
    return (
        <div className='cartoon'>
            <h2 className='cartoon-title'>{title}<FaCameraRetro className='cartoon-icon' /></h2>
            <div className="cartoon-children">
                {children}
            </div>
        </div>
    )
}
