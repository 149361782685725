import React from 'react'
import { FaUser } from 'react-icons/fa';
import './aboutauthor.scss'

export default function AboutAuthor({ title, children, idd }) {
    return (
        <div className='about-author' id={idd}>
            <h2 className='about-author-title'>{title}<FaUser className='about-author-icon' /> </h2>
            <div className="about-author-children">{children}
            </div>
        </div >
    )
}
