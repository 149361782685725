import React from 'react'
import './Contact.scss';

export default function Contact({ title, subtitle, mail }) {

    const mailto = `mailto:${mail}`;
    return (
        <div className='contact-instance'><h2 className='contact-title'>{title}</h2>
            <h3 className='contact-subtitle'>{subtitle}</h3>
            <div className='contact-mail'>
                <a href={mailto}>{mail}</a>
            </div>
        </div>
    )
}
