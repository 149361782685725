import React from "react"
import "./medunarodneizlozbe.scss"

export default function MedunarodneIzlozbe({ children }) {
  return (
    <div className="medunarodne-izlozbe">
      <h3 className="medunarodne-izlozbe-title">International exhibitions</h3>
      {children}
    </div>
  )
}
