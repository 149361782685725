import React from 'react'
import { FaPaintBrush } from 'react-icons/fa';
import './knjigailustrirao.scss'

export default function KnjigaIlustrirao({ children }) {
    return (
        <div className='knjiga-ilustrirao'>
            <FaPaintBrush className='knjiga-ilustrirao-icon' />
            <div className="knjiga-ilustrirao-children">
                {children}
            </div>
        </div>
    )
}
