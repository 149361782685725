import React from "react"
import "./filmoviglavnianimator.scss"

export default function FilmoviGlavniAnimator({ children }) {
  return (
    <div className="filmovi-glavni-animator">
      <h3 className="filmovi-glavni-animator-title">
        Films he worked on as the main animator
      </h3>
      {children}
    </div>
  )
}
