import React from 'react'
import { FaTrophy } from 'react-icons/fa';
import './autorskianimiranifilm.scss'

export default function AutorskiAnimiraniFilm({ children }) {
    return (
        <div className='autorski-animirani-film'>
            <FaTrophy className='autorski-animirani-film-icon' />
            <div className="autorski-animirani-film-children">
                {children}
            </div>
        </div>
    )
}
