import React from "react"
import { Link } from 'gatsby';
import './book.scss'

export default function Book({ coverImg, title, longDescription, children }) {

  // Shorten book description to a full word, add 3 dots
  let descriptionShortened = longDescription.substring(0, 240);

  let lastWordPosition = descriptionShortened.length;
  for (let i = descriptionShortened.length; i > 0; i--) {
    if (descriptionShortened[i] === ' ') { lastWordPosition = i; break; }
  }
  descriptionShortened = descriptionShortened.substring(0, lastWordPosition);
  descriptionShortened += '...';

  const titleFormatted = (title) => {
    return title.replace(/-/g, "").replace(/\s\s+/g, ' ').replace(/ /g, "-").toLowerCase();
  }

  return (
    <div className="book">
      <Link to={titleFormatted(title)}>
        <div className="book-cover-wrapper">
          {children}
        </div>
        <div className="book-title-and-description">
          <h2 className="book-title">{title}</h2>
          <p className="book-description">{descriptionShortened}</p>
        </div>
      </Link>
    </div>
  )
}
